// プライバシー・会社概要・サイトマップのコンテンツタイトル
$(() => {
  const [cnt,path] = [{
    'sitemap':'サイトマップ',
    'privacy':'プライバシーポリシー',
    'profile':'会社概要'
  },location.pathname]
  const page = path.match(/^\/([^/]+)\.php$/i);

  if(!page || !cnt[page[1]]) return false;
  const $title = $(`<h2>${cnt[page[1]]}</h2>`),
        $container = $('<section class="mainImg"><div class="mainImgInner"></div></section>');
  $title.appendTo($container.find('.mainImgInner'));
  $container.insertAfter('#gmenu');
  $('.moduletitle').remove();
  var bodyClass = $('body').attr('class').replace(/[?|&]ipsw=\d/,'');
  $('body').attr('class',bodyClass);
})