//header.html, footer.html読み込み
$(() => {

  const w = window;
  const config = [
    {
      name: 'footer',
      loadhtml: '/asset/html/footer.html',
      $target: $('footer #footerNav'),
      method:'replaceWith',
      callback: function(r,_){
        console.info('%c%s%c is loaded','font-weight: bold',r.url,'');
      }
    },
    {
      name: 'contentFooter',
      loadhtml: '/asset/html/contentFooter.html',
      $target: $('footer,#footer.ui-footer'),
      method:'before',
      callback: function(r,_){
        console.info('%c%s%c is loaded','font-weight: bold',r.url,'');
        $('.homeContentslist').insertBefore('#footer');
      }
    },
    {
      name: 'header',
      loadhtml: '/asset/html/header.html',
      $target: $('header'),
      method:'replaceWith',
      callback: function(r,_){
        console.info('%c%s%c is loaded','font-weight: bold',r.url,'')
        // gmenu current
        $('header .p-gnav').currentMenu({mode:1,default:999});
      }
    }
  ]
  
  $.each(config,function(i,__){
    var mes = 'loadhtml error: ';
    $.get(__.loadhtml)
      .done(function(html){
        if(__.$target[__.method](html).length != 0){ __.callback(this,__) }
        else{
          mes+='Not found DOM $(\'%s\').\nCouldn\'t load \'%s\'';
          console.error(mes,__.$target.selector,this.url);
        }
        w.load[__.name].resolve(html)
      }).fail(function(data){ //ajaxの通信に失敗した場合
        mes+='Ajax failed \'%s\'\n%s (%s)';console.error(mes,this.url,data.statusText,data.status);
        w.load[__.name].reject(data)
        return false;
      });
  })
  
})