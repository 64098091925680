((w,d) => {
  
  // query_stringを定義
  w.query_string = (param) => {
    if(!location.search) return false
    let params = location.search.substr(1).split('&')
    params.map( (v,i) => { const p=v.split('=');params[p[0]]=p[1]})
    return params[param]
  }
  
  $(() => {

    // ポップアップウインドウ
    $(d).on('click','[data-plugin=openwindow]',function(e){
      e.preventDefault();
      var $this = $(this),
          data = $this.data(),
          size = 'width='+(data.width||600)+', height='+(data.height||500)+',';
      w.open(this.href,(data.title||'share this site'),size+"menubar=no, toolbar=no, scrollbars=yes");
    });

    // スマホフッターカスタム
    (function(_){ if(!$.UA.is_mobile && _.$footer.length == 0) return;
      _.$footer.parent().insertAfter( _.$needle );
    }({
      $footer: $('.detailFooter .pageingBtnBlock'),
      $needle: ( $('.listContents').length )? $('.listContents') : $('.element_detail').parent()
    }));

    // FacebookSDK
    // $.ajaxSetup({ cache: false });
    // $.getScript('//connect.facebook.net/ja_JP/sdk.js', function(){
    //   FB.init({
    //     appId: '759600244139396',
    //     xfbml: true,
    //     version: 'v2.8'
    //   });
    // });

  })

  $(w).on('load',()=>{

    // table.responsiveを外包するwrapperを作る(readyfunctionに移す)
    if( is_mobile() ){  // eslint-disable-line no-undef
      (function(_){
        _.$table.each(function(){
          $(this).wrapAll($('<div />').addClass(_.wrapperClass));
        });
      }({
        $table:$('table.responsive'),
        wrapperClass:'tableResponsiveWrappr'
      }));
    }

    // アンカーリンクスクロール設定
    $(d).on('click','a[href^="#"],a[href*="#"]',function(e){
      var path = $(this).attr('href').match(/^([^#]+)(#.*)?/)||[];
      if( path[1] === location.pathname ){
        e.preventDefault();
        $(this).attr('href',path[2]);
      }
      var speed =   500,
          href =    $(this).attr('href'),
          $target = $(href == '#pagetop' || href == '#top' ? 'html' : href ),
          pos =     $target.offset().top;
          if($.UA.is_mobile){
            var headerHeight = $('.ui-header-fixed').height();
            pos = (pos - headerHeight);
          }
      $('body,html').animate({scrollTop:pos},speed,'easeInOutExpo');
      if(href!='#pagetop' && href!='#top')
        window.history.pushState(null, null, this.hash);
      return false;
    });

    // ハッシュURLでスクロール
    (function(_){
      if(_.hash != ''){
        var pos = $(_.hash).offset().top;
        if($.UA.is_mobile){
          var headerHeight = $('.ui-header-fixed').height();
          pos = (pos - headerHeight);
        }
        _.$target.animate({'scrollTop':pos},200,'easeInOutExpo');
      }
    }({
      hash:location.hash,
      $target:$('body,html')
    }));

    // jqueryMobileのアンカーリンク対策
    (function(){
      if(is_mobile()){  // eslint-disable-line no-undef
        $('a[href*="#"]').attr('data-ajax','false');
      }
      if(is_mobile() && location.hash){  // eslint-disable-line no-undef
        $(document).bind('pageshow',function(e){
          (function(w,scrolltop,headerHeight){
            $(document).bind("silentscroll",function(e,data) {
              $(this).unbind(e);
              $(w).scrollTop(scrolltop - headerHeight);
            });
          }('body,html', $(location.hash).offset().top , $('.ui-header-fixed').height() ));
        });
      }
    }());

  })

})(window,document);