export default function INIT() {
  /*****
   * Promise
   */
  window.load = {
    'header': new $.Deferred,
    'contentFooter': new $.Deferred,
    'footer': new $.Deferred,
    'mapScript': new $.Deferred,
  };

  // DOM Ready
  $(() => {
    // <body>にクラス付与
    $.bodyClass();
    // svgスプライト読み込み
    $.loadSVG();
    // htmlデータ読み込み
    $('[data-load-html]').loadHtml();
    // ページングテキスト
    $.pagingText({
      prev:'PREV',
      next:'NEXT',
      list:'LIST'
    })
  })

  $(window).on('load',() => {});

}