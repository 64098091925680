import 'jquery.easing'
import './plugins/plugins'
import './plugins/utility'
import './plugins/partsloader'
import './plugins/pagetop'
import './plugins/contentTitle'
// import './plugins/googlemap'
import INIT from './modules/init'
import VIEWPORTS from './modules/viewports'
import TAGMANAGER from './modules/tagmanager'
// import GOOGLEMAP from './modules/googlemap'

// ページ読み込み
import HOME from './page/home'

/*****
 * サイト設定
 */
window.siteinfo = {
  tagmanager:       'GTM-K7HCZSF',
  viewport:         'width=1200',
  mapApiKeys: {
    'local':          'AIzaSyCTB3MSMYkTjSz6l30k79XflmmL_FcOxkg', //development
    'www.example.jp': '' //production
  }
}

/*****
 * コンテンツごとのファイル読み込み
 */
$.require({
  disableCache:false,
  '*':{
    '/': ['/asset/css/home.min.css?ts=202012141415'],
    '/about': ['/asset/css/about.min.css?ts=202012141415'],
    '/service': ['/asset/css/service.min.css?ts=202012141415'],
    '/recruit': ['/asset/css/recruit.min.css?ts=202012141415'],
  },
})
switch(location.pathname){
  // トップページ
  case '/': HOME()
}


/*****
 * モジュール読み込み
 */
INIT()
VIEWPORTS() // Viewportの置き換え
TAGMANAGER() // GoogleTagManagerの挿入
// GOOGLEMAP() // Googlemap実行パート
