// ページトップリンクをスクロール量で表示する
$(() => {
  $.scrollspy({
    trigger:200,
    onBefore: (/* pos */) => {
      $('.p-gotop__wrap').addClass('hidden');
    },
    onAfter: () => {
      $('.p-gotop__wrap').removeClass('hidden');
    }
  })
})